import React, { useState } from 'react';
import {
  Card,
  CardContent,
  List,
  ListItem,
  Avatar,
  TextField,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Grid,
  ListItemText,
} from '@mui/material';
import {
  Home,
  Search,
  AddCircle,
  OndemandVideoOutlined,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import DashboardHeader from './header';

function LeftPanel({
  chatListData,
  setSelectedChat,
  selectedChat,
  setPage,
  setMessages,
  tabValue,
  setTabValue,
}) {
  const theme = useTheme();
  const { userInfo } = useSelector((state) => state.userInfo);

  // State for managing tab selection

  return (
    <Grid>
      <Card
        style={{
          height: 'calc(100vh - 64px)',
          borderRadius: 0,
          backgroundColor: '#f5f5f5',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Chat List Content */}
        <CardContent
          style={{
            paddingBottom: 16,
            flexGrow: 1, // This will make the list grow to fill the available space
            overflowY: 'auto', // Allows scrolling if the chat list is too long
          }}
        >
          {/* Search Box */}
          <TextField fullWidth placeholder="Search" />

          {/* Chat List */}
          <List>
            {chatListData?.map((chatRoom) => (
              <ListItem
                button
                key={chatRoom.id}
                onClick={() => {
                  setSelectedChat(chatRoom);

                  setMessages(null);
                  setPage(1);
                  setTabValue(0);
                }}
                selected={selectedChat?.id === chatRoom.id}
                style={{
                  borderRadius: 8,
                  marginBottom: 8,
                  padding: '10px 15px',
                  cursor: 'pointer',
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    marginRight: 12,
                  }}
                  src={`https://soapboxapi.megahoot.net/profile-pictures/${chatRoom?.profilePic}`}
                >
                  {chatRoom.name?.slice(0, 1) ||
                    chatRoom.username?.slice(0, 1) ||
                    chatRoom.chatFrom?.slice(0, 1)}
                </Avatar>
                <ListItemText
                  primary={
                    chatRoom.name || chatRoom.username || chatRoom.chatFrom
                  }
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>

        {/* Bottom Navigation */}
        <Paper
          elevation={3}
          sx={{
            width: '30%',
            position: 'fixed',
            bottom: 0,
          }}
        >
          <BottomNavigation
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
            showLabels
          >
            <BottomNavigationAction label="Home" icon={<Home />} />
            <BottomNavigationAction label="Discover" icon={<Search />} />
            <BottomNavigationAction
              label="Post"
              icon={<AddCircle sx={{ fontSize: 40 }} />}
              sx={{
                marginTop: '-20px',
              }}
            />
            <BottomNavigationAction
              label="Soapbox"
              icon={<OndemandVideoOutlined />}
            />
            <BottomNavigationAction
              label="Me"
              icon={
                <Avatar
                  src={`https://soapboxapi.megahoot.net/profile-pictures/${userInfo?.profilePic}`}
                  style={{
                    backgroundColor: theme.palette.primary.light,
                    width: '30px',
                    height: '30px',
                  }}
                />
              }
            />
          </BottomNavigation>
        </Paper>
      </Card>
    </Grid>
  );
}

export default LeftPanel;
