import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  ListItem,
  IconButton,
  TextField,
  Button,
  InputAdornment,
  useMediaQuery,
  Avatar,
  Box,
  CircularProgress,
  Dialog,
} from '@mui/material';
import {
  ArrowBack,
  MoreVert,
  Call,
  Send,
  Mic,
  AttachFile,
  Videocam,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setChatDataList } from '../../redux/reducers/userSlice';
import { useRouter } from '../../components/hooks';
import MessageCard from './messageCard';
import { v4 as uuidv4 } from 'uuid';
import socket, { startSocket } from './socketChat';
import Feeds from './feeds';
import { Link } from 'react-router-dom';
import VideoCallView from './videoCall';
import AudioCallView from './audioCall';
import MiniProgramsCatalogue from './miniPrograms/miniProgramsCatalogue';

function ChatRoom({ selectedChat, setSelectedChat, messages, setMessages }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { userInfo, fcmToken } = useSelector((state) => state.userInfo);

  const [newMessage, setNewMessage] = useState('');

  const [page, setPage] = useState(1); // Track page state for pagination
  const [openCallConfirm, setOpenCallConfirm] = useState(false);
  const [callType, setCallType] = useState(''); // 'audio' or 'video'
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [videoCallEnable, setVideoCallEnable] = useState(false);
  const [audioCallEnable, setAudioCallEnable] = useState(false);
  const [callState, setCallState] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const messagesEndRef = useRef(null); // For scrolling to the bottom
  const chatContainerRef = useRef(null); // For detecting scroll to top

  // useEffect(() => {
  //   if (userInfo && userInfo.username) {
  //     socket.emit('join', { username: userInfo.username });

  //     socket.on('receiveMessage', (message) => {
  //       setMessages((prevMessages) => [...prevMessages, message]);
  //     });

  //     socket.on('typing', (data) => {
  //       if (data.isTyping && data.chatRoomId === selectedChat?.id) {
  //         setTyping(true);
  //       } else {
  //         setTyping(false);
  //       }
  //     });
  //   } else {
  //     router.push('/');
  //   }

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, [userInfo]);

  useEffect(() => {
    if (selectedChat && selectedChat.chatFrom) {
      fetchSelectedChatData(page);
    }
  }, [selectedChat]);

  const fetchSelectedChatData = (page) => {
    axios
      .post('https://soapboxapi.megahoot.net/upload/getChatDataPrivate', {
        to: selectedChat.chatFrom,
        from: selectedChat.chatTo,
        page: page,
        pageSize: 100,
      })
      .then((res) => {
        const data = res.data.map((e) => ({
          _id: e.threadId,
          text: e.chat.message,
          createdAt: e.createdAt,
          seen: e.seen,
          user: {
            _id: e.chatFrom,
            name: e.chatFrom,
            avatar: `https://soapboxapi.megahoot.net/profile-pictures/${e.chat.profilePic}`,
          },
        }));
        if (page === 1) {
          setMessages(data);
        } else {
          setMessages((prevMessages) => [...data, ...prevMessages]);
        }
      });
  };

  const handleOpenCallConfirm = (type) => {
    setCallType(type);
    setOpenCallConfirm(true);

    if (type === 'audio') {
      setAudioCallEnable(true);
    } else {
      setVideoCallEnable(true);
    }
  };

  const handleCloseCallConfirm = () => {
    setOpenCallConfirm(false);
    setVideoCallEnable(false);
    setAudioCallEnable(false);
  };

  const handleConfirmCall = () => {
    // Add your logic to initiate the call
    handleCloseCallConfirm();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function isEmoji(str) {
    var ranges = [
      '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])', // U+1F680 to U+1F6FF
    ];

    if (str?.match(ranges.join('|'))) {
      return true;
    } else {
      return false;
    }
  }
  const [isEphemeral, setIsEphemeral] = useState(false);

  const handleSocketEmit = () => {
    let id = uuidv4();
    let today = new Date();
    let timestamp =
      today.toLocaleTimeString() + ' ' + today.toLocaleDateString();
    const message = newMessage;

    const payload = {
      threadId: id,
      to: selectedChat.chatFrom,
      from: userInfo.username,
      isClub: 0,
      isPrivate: 1,
      isCommunity: 0,
      name: selectedChat.name,
      message: message,
      replyParent: '',
      profilePic: userInfo?.profilePic,
      isEmoji: isEmoji(message),
      timestamp: timestamp,
      ephemeral: isEphemeral ? true : false,
      deviceToken: fcmToken ?? 'fcmToken', // fcmToken is of the user to whom message is going to be dleivered
    }; // Assuming the user sends the message

    const newMessagesData = [
      {
        _id: id,
        createdAt: today.toUTCString(),
        text: newMessage,
        user: {
          _id: userInfo.username,
          avatar: `https://soapboxapi.megahoot.net/profile-pictures/${userInfo.profilePic}`,
          name: userInfo.name,
        },

        sent: true,
        received: false,
        ephemeral: isEphemeral,
      },
    ];
    socket.emit('private-message-soapbox', payload);
    setMessages((prev) => {
      // Check if newMessagesData is defined and has at least one message
      if (newMessagesData && newMessagesData.length > 0) {
        return [newMessagesData[0], ...prev];
      }
      return prev; // Return previous state if no new messages
    });

    setNewMessage('');
  };

  const handleSocketTyping = () => {
    socket.emit('typing', {
      to: selectedChat.chatFrom,
      from: userInfo?.username,
    });
  };

  useEffect(() => {
    if (userInfo && selectedChat) {
      socket.on('receive-private-chat-soapbox', (data) => {
        if (
          data.to === userInfo.username &&
          data.from === selectedChat.chatFrom
        ) {
          const sentMessage = {
            _id: data.threadId,
            text: data.message,
            ephemeral: data.ephemeral,
            createdAt: data.timestamp,
            replyParent: data?.replyParent,
            isPecuKey: data?.isPecuKey,
            user: {
              _id: data.from,
              name: data.chatFrom,
              avatar: `https://soapboxapi.megahoot.net/profile-pictures/${data.profilePic}`,
            },
          };

          setMessages((prev) => {
            // Check if newMessagesData is defined and has at least one message
            if (sentMessage) {
              return [sentMessage, ...prev];
            }
            return prev; // Return previous state if no new messages
          });
          // setNewMessage(sentMessage);

          socket.emit('seen', {
            to: selectedChat.chatFrom,
            from: userInfo.username,
            ...sentMessage,
            seen: 1,
          });
          // chat: {
          //   from: data.from,
          //   isClub: 0,
          //   isCommunity: 0,
          //   isEmoji: isEmoji(data.message),
          //   isPrivate: 1,
          //   message: data.message,
          //   name: data.name,
          //   profilePic: `https://soapboxapi.megahoot.net/profile-pictures/${data.profilePic}`,
          //   to: data.to,
          //   threadId: data.threadId,
          //   timestamp: data.timestamp,
          //   isSticker: data.isSticker,
          // },
          // to: data.to,
          // from: data.from,
          // chatFrom: data.from,
          // isClub: 0,
          // isPrivate: 1,
          // isCommunity: 0,
          // name: data.name,
          // message: data.message,
          // isEmoji: isEmoji(data.message),
          // threadId: data.threadId,
          // timestamp: timestamp,
          //}; // Assuming the user sends the message

          // if (messages && messages.length > 0) {
          //   const alldata = messages;
          //   alldata.push(sentMessage);
          //   setMessages(alldata);
          // } else {
          //   // messagesubmitPrivate(newMessage);
          //   setMessages([sentMessage]);
          // }
        }
      });
      // socket.on('received-private-message-soapbox-reply', (data) => {
      //   const { messagePayload, to, from } = data;
      //   if (data.to == chatTo && data.from == chatFrom) {
      //     setIncomingEmojiReply(messagePayload);
      //   }
      // });

      socket.on('typing', ({ to, from }) => {
        if (to === userInfo.username && from === selectedChat.chatFrom) {
          setIsTyping(true);
          const typingTimeout = setTimeout(() => {
            setIsTyping(false);
          }, 10000);

          // Clear the timeout when the 'typing' event is received again
          socket.once('typing', ({ to: nextTo, from: nextFrom }) => {
            if (
              nextTo !== userInfo.username ||
              nextFrom !== selectedChat.chatFrom
            ) {
              clearTimeout(typingTimeout);
              setIsTyping(false);
            }
          });
        }
      });
    }
  }, [userInfo, selectedChat]);

  // useEffect(() => {
  //   startSocket();
  // }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12} // Occupies 2/3rd of the screen on larger devices
      style={{
        borderLeft: '1px solid #ddd',
        height: '100vh',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Card
        style={{
          height: '100%',
          borderRadius: 0,
          display: 'flex',
          flexDirection: 'column',
          //background: selectedChat ? '#fff' : 'url(/bg.png)',
          width: '100%',
        }}
      >
        {selectedChat ? (
          <>
            <CardContent
              style={{
                padding: '10px 20px',
                borderBottom: '1px solid #ddd',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {isMobile && (
                <IconButton onClick={() => setSelectedChat(null)}>
                  <ArrowBack />
                </IconButton>
              )}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to={`/profile/${selectedChat.chatFrom}`}>
                  <Avatar
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      marginRight: 12,
                    }}
                    src={`https://soapboxapi.megahoot.net/profile-pictures/${selectedChat?.profilePic}`}
                  >
                    {selectedChat.name?.slice(0, 1)}
                  </Avatar>
                </Link>
                <Link
                  to={`/profile/${selectedChat.chatFrom}`}
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.primary.main,
                  }}
                >
                  <Typography variant="h6" style={{ fontWeight: 600 }}>
                    {selectedChat.name}
                  </Typography>
                </Link>
              </div>
              <div>
                <IconButton onClick={() => handleOpenCallConfirm('audio')}>
                  <Call />
                </IconButton>
                <IconButton onClick={() => handleOpenCallConfirm('video')}>
                  <Videocam />
                </IconButton>
                <IconButton onClick={handleClick}>
                  <MoreVert />
                </IconButton>
              </div>
            </CardContent>

            <div
              ref={chatContainerRef}
              style={{
                flex: 1,
                overflowY: 'auto',
                padding: '20px 20px 0 20px',
                backgroundColor: '#f9f9f9',
                display: 'flex',
                flexDirection: 'column-reverse',
                width: '100%',
                marginBottom: '150px',
              }}
            >
              <Box
                sx={{
                  minHeight: '30px',

                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {isTyping && (
                  <img src="/typing.gif" width="100px" alt="typing" />
                )}
              </Box>
              {messages && messages.length > 0 ? (
                messages?.map((message, index) => (
                  <ListItem
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent:
                        message?.user?._id === userInfo.username
                          ? 'flex-end'
                          : 'flex-start',
                    }}
                  >
                    <MessageCard message={message} />
                  </ListItem>
                ))
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="100%"
                >
                  <CircularProgress />
                </Box>
              )}
            </div>

            <CardContent
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '2px 0px 2px 0px',
                position: 'fixed',
                bottom: 0,
                backgroundColor: '#fff',
                width: '70%',
              }}
            >
              <TextField
                onFocusCapture={() => handleSocketTyping()}
                fullWidth
                variant="outlined"
                size="small"
                sx={{ input: { py: '1rem' } }}
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleSocketEmit(newMessage);
                }}
                placeholder="Type a message"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <Mic />
                      </IconButton>
                      <IconButton>
                        <AttachFile />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSocketEmit(newMessage)}
                        disabled={!newMessage.trim()}
                      >
                        <Send />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </CardContent>
          </>
        ) : (
          <MiniProgramsCatalogue />
        )}
      </Card>

      {/* Call confirmation dialog */}
      <Dialog
        open={videoCallEnable}
        onClose={() => {
          return;
        }}
        fullWidth={callState === 'WEBRTC_ROOM' ? true : false}
        maxWidth={callState === 'WEBRTC_ROOM' ? 'lg' : '300px'}
      >
        {videoCallEnable && (
          <VideoCallView
            receivedData={{
              profilePic: selectedChat.profilePic,
              name: selectedChat.name,
              calleId: selectedChat.chatFrom,
              callerId: userInfo.username,
              fcmToken: selectedChat.fcmToken,
              type: 'JOIN',
            }}
            setVideoCallEnable={setVideoCallEnable}
            setCallType={setCallType}
            setOpenCallConfirm={setOpenCallConfirm}
            setCallState={setCallState}
          />
        )}
      </Dialog>

      <Dialog
        open={audioCallEnable}
        onClose={() => {
          return;
        }}
        fullWidth={callState === 'WEBRTC_ROOM' ? true : false}
        maxWidth={callState === 'WEBRTC_ROOM' ? 'sm' : '300px'}
      >
        {audioCallEnable && (
          <AudioCallView
            receivedData={{
              profilePic: selectedChat.profilePic,
              name: selectedChat.name,
              calleId: selectedChat.chatFrom,
              callerId: userInfo.username,
              fcmToken: selectedChat.fcmToken,
              type: 'JOIN',
            }}
            setAudioCallEnable={setAudioCallEnable}
            setCallType={setCallType}
            setOpenCallConfirm={setOpenCallConfirm}
            setCallState={setCallState}
            ChatHeader={() => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.8rem',
                  backgroundColor: '#3d215b',
                }}
              >
                <Link to={`/profile/${selectedChat.chatFrom}`}>
                  <Avatar
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      marginRight: 12,
                    }}
                    src={`https://soapboxapi.megahoot.net/profile-pictures/${selectedChat?.profilePic}`}
                  >
                    {selectedChat.name?.slice(0, 1)}
                  </Avatar>
                </Link>
                <Link
                  to={`/profile/${selectedChat.chatFrom}`}
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.primary.main,
                  }}
                >
                  <Typography variant="h6" style={{ fontWeight: 600 }}>
                    {selectedChat.name}
                  </Typography>
                </Link>
              </div>
            )}
          />
        )}
      </Dialog>
    </Grid>
  );
}

export default ChatRoom;
