import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Typography,
  IconButton,
  Paper,
  Avatar,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { useTheme } from '@emotion/react';

const channelsData = [
  { id: 1, name: 'General', joined: true },
  { id: 2, name: 'Sports', joined: false },
  { id: 3, name: 'Movies', joined: false },
];

const ChannelChat = () => {
  const [channels, setChannels] = useState(channelsData);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const theme = useTheme();
  const handleSelectChannel = (channel) => {
    setSelectedChannel(channel);
    // Load previous chat data or reset for the new channel
    setMessages([]);
  };

  const fetchChannelData = () => {
    axios.get('https://soapboxapi.megahoot.net/private-clubs').then((res) => {
      setChannelList(res.data);
    });
  };

  const handleJoinChannel = (channelId) => {
    setChannels(
      channels.map((ch) => (ch.id === channelId ? { ...ch, joined: true } : ch))
    );
  };

  const handleLeaveChannel = (channelId) => {
    setChannels(
      channels.map((ch) =>
        ch.id === channelId ? { ...ch, joined: false } : ch
      )
    );
    setSelectedChannel(null);
  };

  const handleSendMessage = () => {
    if (message.trim() && selectedChannel) {
      setMessages((prev) => [
        ...prev,
        {
          text: message,
          sender: 'You',
          timestamp: new Date().toLocaleTimeString(),
        },
      ]);
      setMessage('');
    }
  };

  useEffect(() => {
    fetchChannelData();
  }, []);

  return (
    <Box display="flex" bgcolor="#f0f0f0" minHeight={'90vh'} maxHeight={'90vh'}>
      {/* Left Sidebar - Channel List */}
      <Paper
        elevation={3}
        sx={{ width: '300px', padding: 2, maxHeight: '90vh', overflow: 'auto' }}
      >
        <Typography variant="h6" gutterBottom>
          Channels
        </Typography>
        <List>
          {channelList.map((channel) => (
            <ListItem
              key={channel.id}
              button
              selected={selectedChannel?.id === channel.id}
              onClick={() => handleSelectChannel(channel)}
            >
              {' '}
              <Avatar
                src={`https://soapboxapi.megahoot.net/profile-pictures/${channel?.profilePic}`}
                sx={{ marginRight: 2 }}
              ></Avatar>
              <ListItemText
                primary={channel.name}
                secondary={`@${channel.username}`}
              />
              {!channel.joined ? (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleJoinChannel(channel.id)}
                >
                  Join
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => handleLeaveChannel(channel.id)}
                >
                  Leave
                </Button>
              )}
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Right Panel - Chat Room */}
      <Box flex={1} display="flex" flexDirection="column">
        {/* Selected Channel Header */}
        {selectedChannel ? (
          <Box
            sx={{
              bgcolor: theme.palette.primary.main,
              color: '#fff',
              padding: 2,
            }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">{selectedChannel.name} Channel</Typography>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => handleLeaveChannel(selectedChannel.id)}
            >
              Request Access
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              bgcolor: theme.palette.primary.main,
              color: '#fff',
              padding: 2,
            }}
            textAlign="center"
          >
            <Typography variant="h6">Select a Channel to Join</Typography>
          </Box>
        )}

        {/* Chat Messages */}
        <Box flex={1} p={2} overflow="auto">
          {selectedChannel ? (
            <>
              {messages.length > 0 ? (
                messages.map((msg, index) => (
                  <Box
                    key={index}
                    sx={{
                      mb: 1,
                      display: 'flex',
                      justifyContent:
                        msg.sender === 'You' ? 'flex-end' : 'flex-start',
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: msg.sender === 'You' ? '#1976d2' : '#f5f5f5',
                        color: msg.sender === 'You' ? '#fff' : '#000',
                        padding: 1,
                        borderRadius: 2,
                        maxWidth: '60%',
                      }}
                    >
                      <Typography variant="body1">{msg.text}</Typography>
                      <Typography
                        variant="caption"
                        sx={{ display: 'block', mt: 0.5, textAlign: 'right' }}
                      >
                        {msg.timestamp}
                      </Typography>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography color="textSecondary">
                  No messages yet. Start the conversation!
                </Typography>
              )}
            </>
          ) : (
            <Typography>Select a channel to see the messages.</Typography>
          )}
        </Box>

        {/* Message Input */}
        {selectedChannel && selectedChannel.joined && (
          <Box
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              borderTop: '1px solid #ddd',
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type a message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSendMessage();
              }}
            />
            <IconButton
              color="primary"
              onClick={handleSendMessage}
              sx={{ marginLeft: 2 }}
            >
              <SendIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChannelChat;
