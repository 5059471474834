import React, { useState } from 'react';
import {
  Typography,
  Avatar,
  IconButton,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { logOut } from '../../redux/reducers/userSlice';
import { useDispatch } from 'react-redux';
import { useRouter } from '../../components/hooks';
import {
  Email,
  NotificationAddOutlined,
  Notifications,
  WalletOutlined,
} from '@mui/icons-material';

function DashboardHeader({ image, handleHeaderTabClick }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLogoutConfirm, setOpenLogoutConfirm] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    setOpenLogoutConfirm(true);
    handleClose();
  };

  const handleLogoutConfirm = () => {
    // Add your logout logic here

    setOpenLogoutConfirm(false);
    dispatch(logOut());
    router.push('/');
  };

  const handleLogoutCancel = () => {
    setOpenLogoutConfirm(false);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <Toolbar>
          <span style={{ flexGrow: 1, marginTop: '8px' }}>
            <img src="/chathive-w.png" width="100px" alt="mChatHive" />
          </span>
          <Stack direction="row" gap={1}>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('CHANNELS')}
            >
              CHANNELS
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('CHANNELS')}
            >
              CLUBS
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('MINIPROGRAMS')}
            >
              MINI PROGRAMS
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('POOLS')}
            >
              POOLS
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('EVENTS')}
            >
              EVENTS
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('WALLET')}
            >
              <WalletOutlined />
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('EMAIL')}
            >
              <Email />
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleHeaderTabClick('NOTIFICATIONS')}
            >
              <Notifications />
            </Button>
          </Stack>

          <IconButton color="inherit" onClick={handleClick}>
            <Avatar
              src={image}
              style={{
                backgroundColor: theme.palette.primary.light,
              }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Popup Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            /* Add your logic for editing profile */
          }}
        >
          Edit Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            /* Add your logic for settings */
          }}
        >
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>

      {/* Logout Confirmation Modal */}
      <Dialog open={openLogoutConfirm} onClose={handleLogoutCancel}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to logout?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogoutConfirm} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DashboardHeader;
