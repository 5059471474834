import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Typography,
  TextField,
  IconButton,
  Card,
  CardMedia,
  CircularProgress,
  Box,
  InputAdornment,
  Divider,
  Dialog,
  CardContent,
  Avatar,
  Button,
} from '@mui/material';
import {
  MoreVert,
  Search as SearchIcon,
  Visibility,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { setDiscoverData } from '../../redux/reducers/userSlice';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RepeatIcon from '@mui/icons-material/Repeat';

const DiscoverScreen = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedMenu, setSelectedMenu] = useState('People');
  const [exploreData, setExploreData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState(null);
  const [openPost, setOpenPost] = useState(false);

  const fetchExploreData = (page) => {
    setLoading(true);
    axios
      .get(
        `https://soapboxapi.megahoot.net/upload/trending/public/p?page=${page}&limit=15`
      )
      .then((res) => {
        if (res && res.data && res.data.results) {
          setExploreData((prev) => [...prev, ...res.data.results]);
          if (res.data.results.length === 0) {
            setHasMore(false); // If no more data, stop infinite scroll
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchExploreData(page);
  }, [page]);

  const handleSearch = (event) => {
    const text = event.target.value;
    if (text && text.length > 0) {
      axios
        .get(
          `https://soapboxapi.megahoot.net/user/search/p?keyword=${text}&page=1&limit=10`
        )
        .then((res) => {
          setSearchResult(res.data.results);
        })
        .catch((err) => console.log(err));
    } else {
      setSearchResult([]);
    }
  };

  const handleProfileClick = (item) => {
    // Navigate to public profile page
  };

  const renderItem = (item) => (
    <Card key={item.id} style={{ margin: 'auto', width: '100%' }}>
      <CardMedia
        onClick={() => {
          setOpenPost(true);
          setPostData(item);
        }}
        loading="lazy"
        component={item.mimeType?.includes('image') ? 'img' : 'video'}
        image={`https://soapboxapi.megahoot.net/images/${item.image}`}
        style={{
          height: 380,
          objectFit: 'cover',
          width: '100%',
        }}
      />
    </Card>
  );

  const renderUser = (item) => (
    <Box
      key={item.id}
      display="flex"
      alignItems="center"
      p={1}
      m={1}
      style={{ width: '100%' }}
    >
      <CardMedia
        component="img"
        image={`https://soapboxapi.megahoot.net/profile-pictures/${item.profilePic}`}
        style={{ width: 50, height: 50, borderRadius: '50%' }}
        loading="lazy"
      />
      <Box ml={2}>
        <Typography variant="body1">{item.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          @{item.username}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="lg">
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          variant="outlined"
          placeholder="Search"
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Box>

      <Grid container spacing={2}>
        {/* Right Panel for Search Results */}

        {/* Left Panel for Explore Data */}
        <Grid item xs={12} md={12}>
          {searchResult.length === 0 && (
            <Box style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
              <InfiniteScroll
                scrollThreshold={0.7}
                height={'calc(100vh - 64px)'}
                dataLength={exploreData.length}
                next={() => setPage((prev) => prev + 1)}
                hasMore={hasMore}
                loader={
                  <Box display="flex" justifyContent="center" my={4}>
                    <CircularProgress />
                  </Box>
                }
                endMessage={
                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                  >
                    No more results
                  </Typography>
                }
              >
                <Grid container spacing={2}>
                  {exploreData.map((item) => (
                    <Grid item xs={12} sm={6} md={3} key={item.id}>
                      {renderItem(item)}
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            </Box>
          )}
        </Grid>
      </Grid>
      {postData && postData.id && (
        <Dialog
          open={openPost}
          onClose={() => {
            setOpenPost(false);
            setPostData(null);
          }}
          maxWidth="lg"
          fullWidth={true}
        >
          <Card
            id={`feed-item-${postData?.id}`}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 'auto',
              maxWidth: '400px',
              margin: '0 auto',
              my: 5,
              borderRadius: '20px',
              position: 'relative',
              backgroundColor: '#fff',
              padding: '1px',
            }}
          >
            {postData.mimeType.includes('video') ? (
              <>
                <CardMedia
                  component="video"
                  loading="lazy"
                  src={`https://soapboxapi.megahoot.net/images/${postData.image}`}
                  poster={`https://soapboxapi.megahoot.net/images/${postData.audioPoster}`}
                  controls={true}
                  sx={{ width: 'auto', height: '70vh', objectFit: 'cover' }}
                  muted={false}
                />
                {/* <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: theme.palette.primary.main,
              zIndex: 2,
            }}
            onClick={() => togglePlayPause(index)}
          >
            {playingVideos[index] ? (
              <PauseIcon sx={{ fontSize: 40, color: '#fff' }} />
            ) : (
              <PlayArrowIcon sx={{ fontSize: 40, color: '#fff' }} />
            )}
          </IconButton> */}
              </>
            ) : (
              <CardMedia
                component="img"
                loading="lazy"
                image={`https://soapboxapi.megahoot.net/images/${postData.image}`}
                alt="feed"
                sx={{ width: '100%', height: '70vh', objectFit: 'cover' }}
              />
            )}
            <CardContent>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Link to={`/profile/${postData.authorUsername}`}>
                  <Avatar
                    src={`https://soapboxapi.megahoot.net/profile-pictures/${postData.authorProfilePic}`}
                  />
                </Link>

                <Typography
                  variant="h6"
                  mb={0}
                  component="a"
                  sx={{
                    textDecoration: 'none',
                  }}
                  href={`/profile/${postData.authorUsername}`}
                >
                  {postData.authorUsername}
                </Typography>
                <Button
                  sx={{
                    border: `1px solid ${theme.palette.primary.contrastText}`,
                    color: theme.palette.primary.contrastText,
                    fontSize: '10px',
                    padding: '2px',
                  }}
                >
                  Follow
                </Button>
              </Box>
              <Typography variant="body1" paragraph>
                {postData.caption}
              </Typography>
            </CardContent>
            <CardContent
              sx={{
                position: 'absolute',
                bottom: 200,
                right: 0,
                backgroundColor: '#0000004d',
                color: theme.palette.primary.contrastText,
                width: '50px',
                borderRadius: '30px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  sx={{ flexDirection: 'column' }}
                  // onClick={() => handleLike(item.id)}
                  color="primary"
                >
                  <ThumbUpIcon
                    sx={{ color: theme.palette.primary.contrastText }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ ml: 0.5, color: theme.palette.primary.contrastText }}
                  >
                    {postData.likes}
                  </Typography>
                </IconButton>
                <IconButton
                  sx={{ flexDirection: 'column' }}
                  // onClick={() => handleLike(item.id)}
                  color="primary"
                >
                  <Visibility
                    sx={{ color: theme.palette.primary.contrastText }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ ml: 0.5, color: theme.palette.primary.contrastText }}
                  >
                    {postData.views}
                  </Typography>
                </IconButton>
                <IconButton
                  sx={{ flexDirection: 'column' }}
                  //onClick={() => handleRepost(item.hootId)}
                >
                  <RepeatIcon
                    sx={{ color: theme.palette.primary.contrastText }}
                  />

                  <Typography
                    variant="body2"
                    sx={{ ml: 0.5, color: theme.palette.primary.contrastText }}
                  >
                    {postData.reposts}
                  </Typography>
                </IconButton>
                <IconButton>
                  <MoreVert
                    sx={{ color: theme.palette.primary.contrastText }}
                  />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        </Dialog>
      )}
    </Container>
  );
};

export default DiscoverScreen;
