import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  useMediaQuery,
  Modal,
  Avatar,
  Typography,
  Dialog,
  Button,
  ListItemText,
  ListItem,
  List,
  Stack,
} from '@mui/material';
import LeftPanel from './LeftPanel'; // Import Left Panel
import RightPanel from './RightPanel'; // Import Right Panel
import axios from 'axios';
import { useRouter } from '../../components/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { setChatDataList } from '../../redux/reducers/userSlice';
import ChatRoom from './chatroom';
import DiscoverScreen from './discoverScreen';
import Feeds from './feeds';
import DashboardHeader from './header';
import PublicProfilePage from './publicProfilePage';
import { socketCall as socket } from './socketChat';
import ProfilePage from './profilePage';
import { io } from 'socket.io-client';
import VideoCallView from './videoCall';
import { Link } from 'react-router-dom';
import AudioCallView from './audioCall';
import {
  EditNoteOutlined,
  EmailOutlined,
  MarkEmailUnreadOutlined,
  OpenInBrowser,
} from '@mui/icons-material';
import Email from './email';
import MiniProgramsCatalogue from './miniPrograms/miniProgramsCatalogue';
import ChannelChat from './channels';

function Dashboard() {
  const router = useRouter();
  const dispatch = useDispatch();
  const { userInfo, chatData, chatListData } = useSelector(
    (state) => state.userInfo
  );
  const [videoCallEnable, setVideoCallEnable] = useState(false);
  const [audioCallEnable, setAudioCallEnable] = useState(false);
  const [openCallConfirm, setOpenCallConfirm] = useState(false);
  const [callType, setCallType] = useState(''); // 'audio' or 'video'
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [typing, setTyping] = useState(false);
  const [page, setPage] = useState(1); // Track page state for pagination
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tabValue, setTabValue] = useState(0);
  const [callData, setCallData] = useState(null);
  const [callState, setCallState] = useState(null);
  const [emailView, setEmailView] = useState(false);
  const [emailData, setEmailData] = useState(null);
  const [miniProgramsView, setMiniProgramsView] = useState(false);
  const [channelsView, setChannelView] = useState(false);

  // Fetch chat data and listen for updates via WebSocket
  useEffect(() => {
    if (userInfo && userInfo.username) {
      fetchChatDataList(userInfo.username);
      //  socket.emit('join', { username: userInfo.username });

      socket.on('receiveMessage', (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
        // scrollToBottom(); // Scroll to bottom when a new message arrives
      });

      socket.on('typing', (data) => {
        if (data.isTyping && data.chatRoomId === selectedChat?.id) {
          setTyping(true);
        } else {
          setTyping(false);
        }
      });
    } else {
      router.push('/');
    }

    return () => {
      socket.disconnect();
    };
  }, [userInfo]);

  // Fetch chat list
  const fetchChatDataList = (username) => {
    axios
      .post('https://soapboxapi.megahoot.net/upload/getChatDataPrivateinbox', {
        to: username,
      })
      .then((res) => {
        const payload = res.data.sort(
          (b, a) =>
            new Date(a.chat.timestamp).getTime() -
            new Date(b.chat.timestamp).getTime()
        );
        dispatch(setChatDataList(payload));
      });
  };

  // Handle sending a message
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const messageData = {
        chatRoomId: selectedChat?.id,
        sender: userInfo.username,
        content: newMessage,
        timestamp: new Date().toISOString(),
      };

      socket.emit('sendMessage', messageData);

      setMessages((prevMessages) => [
        ...prevMessages,
        { ...messageData, sender: 'You' },
      ]);
      setNewMessage('');
      // scrollToBottom(); // Scroll to bottom after sending a message
    }
  };

  const handleTyping = () => {
    socket.emit('typing', { chatRoomId: selectedChat?.id, isTyping: true });
    setTimeout(() => {
      socket.emit('typing', { chatRoomId: selectedChat?.id, isTyping: false });
    }, 2000);
  };

  function connectSocketWithQuery(callerId) {
    socket.io.opts.query = { callerId }; // Set the query parameters
    socket.connect(); // Manually connect the socket
  }

  const handleHeaderTabClick = (tab) => {
    if (tab === 'EMAIL') {
      setEmailView(true);
    } else if (tab === 'MINIPROGRAMS') {
      setMiniProgramsView(true);
    } else if (tab === 'CHANNELS') {
      setChannelView(true);
    }
  };

  const fetchEmailData = (username) => {
    axios
      .get(`https://soapboxapi.megahoot.net/emails/inbox/${username}`)
      .then((res) => {
        setEmailData(res.data.reverse());
      });
  };

  useEffect(() => {
    if (userInfo) {
      // const socket = io('https://testrandom.megahoot.net', {
      //   transports: ['websocket', 'polling'], // Ensure WebSocket transport
      //   reconnection: true, // Enable reconnection attempts
      //   reconnectionAttempts: 5, // Number of reconnection attempts
      //   reconnectionDelay: 2000, // Delay between reconnections
      //   query: {
      //     callerId: userInfo.username,
      //   },
      // });
      connectSocketWithQuery(userInfo.username);
      fetchEmailData(userInfo.username);
      socket.on('newCall', (data) => {
        if (data.callType === 'VIDEO') {
          setCallData({
            profilePic: data.profilePic,
            name: data.callerId,
            callerId: userInfo.callerId,
            calleId: data.callerId,
            remoteRTCMessage: data.rtcMessage,
            type: 'INCOMING_CALL',
          });
          setVideoCallEnable(true);
        } else {
          setCallData({
            profilePic: data.profilePic,
            name: data.callerId,
            callerId: userInfo.callerId,
            calleId: data.callerId,
            remoteRTCMessage: data.rtcMessage,
            type: 'INCOMING_CALL',
          });

          setAudioCallEnable(true);
        }

        // remoteRTCMessage.current = data.rtcMessage;
        // otherUserId.current = data.callerId;
        // setType('INCOMING_CALL');
      });

      return () => {
        socket.off('newCall');
      };
    }
  }, [userInfo]);

  return (
    <Grid
      sx={{
        height: '100vh',
        width: '100vw',
        position: 'fixed', // Use relative positioning to accommodate header
      }}
    >
      <DashboardHeader
        handleHeaderTabClick={handleHeaderTabClick}
        image={`https://soapboxapi.megahoot.net/profile-pictures/${userInfo?.profilePic}`}
        sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1200 }} // Ensure header is on top
      />

      <Grid container sx={{ height: 'calc(100% - 64px)' }}>
        {/* Left Side (30%) */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            height: '100%',
            position: 'fixed', // Keep it fixed within the container
            left: 0,
            top: '64px', // Position below the headers
            zIndex: 1100, // Ensure it's below the header
          }}
          width={{ xs: '100%', sm: '30%' }}
        >
          <LeftPanel
            selectedChat={selectedChat}
            isMobile={isMobile}
            chatListData={chatListData}
            setSelectedChat={setSelectedChat}
            setPage={setPage}
            setMessages={setMessages}
            tabValue={tabValue}
            setTabValue={setTabValue}
          />
        </Grid>

        {/* Right Side (70%) */}
        <Grid
          item
          xs={12}
          md={9}
          sx={{
            height: '100%',
            marginLeft: '30%', // Push the right area next to the left fixed area
            position: 'relative', // Add relative positioning to manage z-index
            zIndex: 1000, // Ensure the right container is behind the modals but still visible
            backgroundColor: 'white', // Ensure background stays visible
            overflow: 'hidden', // Prevent content from overflowing
          }}
          display={{ xs: 'none', sm: 'block' }} // Hide image on extra-small screens (xs)
        >
          {tabValue === 0 ? (
            <ChatRoom
              selectedChat={selectedChat}
              setSelectedChat={setSelectedChat}
              messages={messages}
              setMessages={setMessages}
            />
          ) : tabValue === 1 ? (
            <DiscoverScreen />
          ) : tabValue === 2 ? (
            <Feeds />
          ) : tabValue === 3 ? (
            <Feeds />
          ) : tabValue === 4 ? (
            <ProfilePage />
          ) : (
            ''
          )}

          <Dialog
            open={videoCallEnable}
            onClose={() => setVideoCallEnable(false)}
            fullWidth={callState === 'WEBRTC_ROOM' ? true : false}
            maxWidth={callState === 'WEBRTC_ROOM' ? 'lg' : '300px'}
          >
            {/* Video Call Modal */}

            <VideoCallView
              receivedData={callData}
              setVideoCallEnable={setVideoCallEnable}
              setCallType={setCallType}
              setOpenCallConfirm={() => console.log('test')}
              setCallState={setCallState}
            />
          </Dialog>

          <Dialog
            open={audioCallEnable}
            onClose={() => setAudioCallEnable(false)}
            fullWidth={callState === 'WEBRTC_ROOM' ? true : false}
            maxWidth={callState === 'WEBRTC_ROOM' ? 'sm' : '300px'}
          >
            {/* Audio Call Modal */}

            <AudioCallView
              receivedData={callData}
              setAudioCallEnable={setAudioCallEnable}
              setCallType={setCallType}
              setOpenCallConfirm={setOpenCallConfirm}
              setCallState={setCallState}
              ChatHeader={() => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0.8rem',
                    backgroundColor: '#3d215b',
                  }}
                >
                  <Link to={`/profile/${selectedChat?.chatFrom}`}>
                    <Avatar
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        marginRight: 12,
                      }}
                      src={`https://soapboxapi.megahoot.net/profile-pictures/${selectedChat?.profilePic}`}
                    >
                      {selectedChat?.name?.slice(0, 1)}
                    </Avatar>
                  </Link>
                  <Link
                    to={`/profile/${selectedChat?.chatFrom}`}
                    style={{
                      textDecoration: 'none',
                      color: theme.palette.primary.main,
                    }}
                  >
                    <Typography variant="h6" style={{ fontWeight: 600 }}>
                      {selectedChat?.name}
                    </Typography>
                  </Link>
                </div>
              )}
            />
          </Dialog>

          <Dialog
            maxWidth={'xl'}
            open={emailView}
            fullWidth={true}
            onClose={() => setEmailView(false)}
          >
            <Email
              open={emailView}
              fullWidth={true}
              emailData={emailData}
              setEmailView={setEmailView}
            />
          </Dialog>
          <Dialog
            maxWidth={'lg'}
            open={miniProgramsView}
            fullWidth={true}
            onClose={() => setMiniProgramsView(false)}
          >
            <MiniProgramsCatalogue />
          </Dialog>

          <Dialog
            maxWidth={'lg'}
            open={channelsView}
            fullWidth={true}
            onClose={() => setChannelView(false)}
          >
            <ChannelChat />
          </Dialog>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
